/***************
This CSS file is built mobile first. Media queries are at the end of the file
****************/
@mixin appt_booking {
  #appointments {
    #content {
      #appointment-book-container.appt-book {
        overflow: hidden;
        padding: 0;
      }
    }
  }
  .appt-book-page-header {
    color: $color-black-dark;
    display: block;
    text-decoration: none;
    .appt-book-page-header-bg {
      display: block;
      height: auto;
      width: 100%;
    }
    .appt-book-page-header-bg.bg-img-mobile {
      display: none;
    }
  }
  .appt-book-page-header-content {
    .inner_mob {
      display: none;
    }
    h2 {
      margin: 0;
      margin-bottom: 0;
      line-height: 1.2;
    }
    h3 {
      margin: 0;
      margin-bottom: 0;
      line-height: 1.2;
    }
    h4 {
      margin: 0;
      margin-bottom: 0;
      line-height: 1.3;
      padding-bottom: 0;
    }
    p {
      margin: 0;
      margin-bottom: 0;
      line-height: 1.2;
    }
    .section-header {
      margin-top: 3px;
      margin-bottom: 3px;
      font-weight: bold;
      display: none;
    }
  }
  .appt-book {
    .my-appointments {
      width: 100%;
      .appt-book-content-header {
        margin: 35px 0 0;
        p {
          width: 56%;
          margin: 18px auto;
        }
      }
      .appointments-container {
        width: auto;
        overflow: hidden;
        .mobile-header {
          display: none;
        }
      }
      .appointments-container.past {
        height: auto;
        overflow: hidden;
        border-bottom: 1px solid $border-color_light_gray;
        .past-appt-head {
          width: 100%;
          a.view-link {
            text-transform: none;
            color: $color-black-dark;
            display: block;
            height: 84px;
            padding: 43px 0 0;
            letter-spacing: 0.25em;
            border-bottom: 1px solid $border-color_transparent;
            background: $background-color_transparent
              url('/media/export/cms/appointment_booking/common/past_appt_expanded_arrow.gif')
              no-repeat scroll 0 52px;
          }
          .section-header {
            border-bottom: 0 none;
            letter-spacing: 0.1em;
            margin: 20px 0;
            .section-header {
              .inner {
                padding: 0 14px 0 18px;
              }
            }
            .section-header__inner {
              padding: 0 14px 0 18px;
            }
          }
        }
        .appointment-details {
          margin: 0;
        }
      }
      .appointments-container.past.expanded {
        .past-appt-head {
          a.view-link {
            border-bottom: 1px solid $border-color_transparent;
            background: $background-color_transparent
              url('/media/export/cms/appointment_booking/common/past_appt_arrow.gif') no-repeat
              scroll 0 52px;
          }
        }
      }
      .appointment-container {
        width: 100%;
        overflow: hidden;
        margin-bottom: 0;
        border-bottom: 1px solid $border-color_light_gray;
        padding: 20px 0;
        .appointment-details {
          line-height: 22px;
          margin: 14px auto 20px;
          overflow: hidden;
          width: 100%;
          .date-time {
            padding-top: 15px;
          }
          > div {
            h4 {
              letter-spacing: 2px;
              line-height: 18px;
              padding-bottom: 0;
              margin: 1px 2px 2px;
              text-transform: none;
              font-weight: bold;
            }
            span {
              display: block;
              line-height: 2;
            }
          }
          .location {
            a.location-direction {
              display: none;
            }
            a {
              &:not(.virtual-link) {
                border-bottom: 1px solid $color-black-dark;
                padding-bottom: 5px;
                text-decoration: none;
                text-transform: uppercase;
                letter-spacing: 0.23em;
              }
            }
          }
        }
      }
      #my-appointments-past {
        .appointment-container {
          margin-bottom: 3%;
        }
      }
      .appt-booking-links {
        margin: 25px 0;
        h4 {
          margin: 0 0 20px;
          letter-spacing: 0.1em;
        }
        a.button {
          background-color: $color-black-dark;
          float: none;
          letter-spacing: 0.1em;
          color: $white;
          &:hover {
            background: $background-color_gray none repeat scroll 0 0;
            border-color: $border-color_gray;
            color: $white;
            cursor: pointer;
            text-decoration: none;
          }
        }
        .viewcart-buttons {
          a.go-shopping {
            background-color: $background-color_strawberry;
          }
        }
        a.recommended-item__button {
          background-color: $background-color_strawberry;
        }
      }
      .appointments-buttons {
        text-transform: uppercase;
        margin-bottom: 20px;
        .text {
          margin-bottom: 35px;
          span {
            display: block;
            line-height: 18px;
          }
        }
        .change-appt-btn {
          letter-spacing: 0.1em;
          line-height: 0;
          margin-#{$rdirection}: 36px;
          border-bottom: 1px solid $color-black-dark;
          text-decoration: none;
          &:hover {
            background: $background-color_gray none repeat scroll 0 0;
            border-color: $border-color_gray;
            color: $white;
            cursor: pointer;
            text-decoration: none;
          }
        }
        .change-appt-btn.mobile {
          display: none;
        }
        a.cancel-appt-btn {
          border-color: $color-black-dark;
          color: $color-black-dark;
          margin-#{$rdirection}: 29px;
          text-decoration: none;
          border-bottom: 1px solid;
        }
        a.location-direction-links {
          border-bottom: 1px solid $color-black-dark;
          text-transform: uppercase;
          text-decoration: none;
        }
      }
      .appointments-buttons.top {
        display: none;
      }
    }
    .appt-book-sections-container {
      #appt-book-my-appointments-no-appts {
        .button-container {
          display: inline-block;
          margin: 20px 0;
        }
      }
    }
    .appointment-cancellation {
      width: 100%;
      .cancel-content {
        display: none;
      }
      .error-content {
        display: none;
      }
      .button-container {
        display: block;
        margin-top: 40px;
      }
    }
  }
  .viewcart-buttons {
    .appt-book {
      .my-appointments {
        .appt-booking-links {
          a.go-shopping {
            background-color: $white;
          }
        }
      }
    }
    .appt-book-overlay.my-appointments-overlay {
      .overlay-content-container {
        #confirm-cancel {
          .go-shopping {
            width: 152px;
            height: 38px;
          }
          .yes-btn.go-shopping {
            margin-#{$rdirection}: 36px;
          }
        }
      }
    }
  }
  .appt-book-overlay {
    display: none;
    background-color: $background-color_gray_transparent;
    z-index: 100;
    position: absolute;
    top: 50%;
    #{$ldirection}: 50%;
    text-align: center;
    .overlay-content-container {
      display: block;
      background-color: $white;
      border: 3px solid $color-black-dark;
      z-index: 1;
      width: 428px;
      height: auto;
      margin-top: -95px;
      margin-#{$ldirection}: -214px;
      .overlay_container {
        border: 1px solid $color-black-dark;
        height: auto;
        margin: 3px;
        #confirm-cancel {
          padding: 46px 20px;
          .button--dark {
            text-transform: capitalize;
          }
        }
        #appt-canceled {
          padding: 15px;
        }
      }
      .overlay-close {
        display: block;
        #{$rdirection}: 0;
        top: 0;
        background: $background-color_transparent
          url('/media/export/cms/online_appointment_booking/common/overlay_close_btn.gif') no-repeat;
        width: 37px;
        height: 32px;
        cursor: pointer;
        z-index: 3;
        overflow: hidden;
        text-indent: 100%;
        white-space: nowrap;
        float: #{$rdirection};
      }
      .overlay-content {
        display: block;
        h4 {
          letter-spacing: 0.205em;
          margin-bottom: 12px;
          margin-top: 4px;
          text-transform: none;
        }
        p {
          line-height: 1.75;
          margin-top: 19px;
        }
      }
    }
  }
  .appt-book-overlay.appt-book-services-overlay {
    .overlay-content-container {
      width: 428px;
      height: 185px;
      margin-top: -95px;
      margin-#{$ldirection}: -214px;
    }
  }
  .appt-book-overlay.appt-book-book-conflict-overlay {
    .overlay-content-container {
      width: 428px;
      height: 185px;
      margin-top: -95px;
      margin-#{$ldirection}: -214px;
    }
  }
  .appt-book-overlay.appt-book-datepicker-overlay {
    z-index: 1000;
    .overlay-content-container {
      width: 950px;
      height: auto;
      margin-#{$ldirection}: -475px;
      .pika-single {
        color: $color-black-dark;
        border: none;
      }
      .pika-title {
        padding: 0 10px;
        button {
          &:hover {
            background-color: $background-color_transparent;
          }
        }
        .offers-vip__button {
          &:hover {
            background-color: $background-color_transparent;
          }
        }
      }
      .pika-label {
        letter-spacing: 0.25em;
      }
      .pika-table {
        th {
          color: $color-black-dark;
          border-bottom: none;
        }
        abbr[title] {
          border: none;
          text-decoration: none;
          cursor: default;
        }
        abbr {
          border: none;
          text-decoration: none;
          cursor: default;
        }
        td {
          color: $color-black-dark;
          border-bottom: none;
        }
      }
      .pika-button {
        background: $white;
        color: $color-black-dark;
        border-radius: 0;
        &:hover {
          background-color: $color-black-dark;
          color: $white;
          border-radius: 0;
        }
      }
      .pika-prev {
        background: $background-color_transparent
          url('/media/export/cms/appointment_booking/appointments/cal_arrow_left.png') no-repeat;
      }
      .is-rtl {
        .pika-next {
          background: $background-color_transparent
            url('/media/export/cms/appointment_booking/appointments/cal_arrow_left.png') no-repeat;
        }
        .pika-prev {
          background: $background-color_transparent
            url('/media/export/cms/appointment_booking/appointments/cal_arrow_right.png') no-repeat;
        }
      }
      .pika-next {
        background: $background-color_transparent
          url('/media/export/cms/appointment_booking/appointments/cal_arrow_right.png') no-repeat;
      }
    }
  }
  .appt-book-overlay.my-appointments-overlay {
    position: fixed;
    .overlay-content-container {
      width: 431px;
      min-height: 212px;
      margin-top: -95px;
      margin-#{$ldirection}: -214px;
      #confirm-cancel {
        p {
          letter-spacing: 0.2em;
          line-height: 1.55;
          margin-bottom: 17px;
          margin-top: 10px;
          text-transform: none;
        }
        .button.yes-btn {
          height: 23px;
          width: 119px;
          margin-#{$rdirection}: 13px;
        }
        .button.no-btn {
          height: 23px;
          width: 119px;
        }
        .button {
          width: 152px;
          height: 38px;
        }
        .viewcart-buttons {
          .go-shopping {
            width: 152px;
            height: 38px;
          }
          .yes-btn.go-shopping {
            margin-#{$rdirection}: 36px;
          }
        }
        .recommended-item__button {
          width: 152px;
          height: 38px;
        }
        .yes-btn.recommended-item__button {
          margin-#{$rdirection}: 36px;
        }
      }
      .canceled {
        h4 {
          margin: 1px auto 12px;
          line-height: 24px;
          width: 100%;
        }
        p {
          margin: 17px 0;
          span {
            display: block;
            margin-bottom: 5px;
          }
        }
        .book-new-btn {
          background-color: $color-black-dark;
          letter-spacing: 0.25em;
          line-height: 48px;
          margin-bottom: 7px;
          color: $white;
          &:hover {
            background: $background-color_gray none repeat scroll 0 0;
            border-color: $border-color_gray;
            color: $white;
            cursor: pointer;
            text-decoration: none;
          }
        }
      }
    }
  }
  .appt-book-content-header {
    .section-header {
      margin-top: 3px;
      margin-bottom: 3px;
      font-weight: bold;
    }
    display: none;
  }
  .location {
    margin-top: 15px;
    h4 {
      font-weight: bold;
    }
    a {
      display: none;
    }
    a.virtual-link {
      display: block;
    }
  }
  .no-rgba {
    .appt-book-overlay {
      background: $background-color_transparent
        url('/media/export/cms/appointment_booking/common/overlay_tint.png') repeat;
    }
  }
  @media (max-width: $mobile-width-limit) {
    .appt-book-page-header {
      margin: 0 auto 20px;
      .appt-book-page-header-bg.bg-img-pc {
        display: none;
      }
      .appt-book-page-header-bg.bg-img-mobile {
        display: block;
      }
    }
    .appt-book-page-header-content {
      height: 55px;
      .inner {
        display: block;
        text-align: center;
      }
      .inner_mob {
        display: none;
        width: 100%;
        h2 {
          line-height: 0;
          padding-top: 6%;
          width: 100%;
          letter-spacing: 2px;
        }
      }
      h2 {
        border-bottom: 0;
      }
      .section-header {
        width: 100%;
      }
    }
    .appt-book {
      .appt-book-sections-container {
        padding: 0 20px;
      }
      .my-appointments {
        .appt-book-content-header {
          display: none;
          p {
            width: 100%;
          }
        }
        .appointments-container {
          margin: 0;
          .mobile-header {
            text-transform: uppercase;
            display: block;
            border-top: 1px solid $border-color_light_gray;
            border-bottom: 1px solid $border-color_light_gray;
            width: 100%;
            padding: 15px 0;
            margin: 5px 0 23px;
          }
        }
        .appointments-container.past {
          .past-appt-head {
            margin-bottom: 35px;
            padding: 20px;
            a.view-link {
              letter-spacing: 1px;
              height: 54px;
              padding: 13px 0 0;
              background: none;
              &::before {
                border-bottom: none;
              }
            }
            .section-header {
              letter-spacing: 0.2em;
              margin: 0;
              padding: 0;
              &::before {
                background: $background-color_transparent;
              }
              .section-header {
                .inner {
                  letter-spacing: 0.2em;
                  margin: 20px 0;
                  padding: 0 5px;
                }
              }
              .section-header__inner {
                letter-spacing: 0.2em;
                margin: 20px 0;
                padding: 0 5px;
                background-color: $white;
              }
            }
          }
          .appointment-details {
            &:last-child {
              .date-time {
                border-bottom: none;
              }
              .artist {
                border-bottom: none;
              }
            }
            .date-time {
              border-bottom: none;
            }
            .artist {
              border-bottom: none;
            }
          }
          .appointment-container {
            padding: 0 5px;
          }
        }
        .appointments-container.past.expanded {
          .past-appt-head {
            a.view-link {
              background: none;
            }
          }
        }
        .appointment-container {
          .appointment-details {
            line-height: 19px;
            > div {
              float: none;
              width: 100%;
              border: none;
              min-height: 0;
              padding: 0;
              margin: 0 0 35px;
              h4 {
                line-height: 20px;
              }
              span {
                line-height: 20px;
              }
            }
            .location {
              line-height: 20px;
              border-top: none;
              margin: 0 0 20px;
              a.location-direction {
                border: none;
                display: block;
              }
              a {
                padding-bottom: 0;
              }
            }
            .date-time {
              .appt-date {
                display: block;
                margin-#{$rdirection}: 20px;
              }
              .start-date {
                display: inline;
              }
              border-top: none;
              margin: 0 0 20px;
            }
            .your-lessons {
              border-top: none;
              margin: 0 0 20px;
            }
          }
        }
        .appt-booking-links {
          a.button {
            width: 100%;
          }
        }
        .appointments-buttons {
          margin-bottom: 23px;
          .text {
            margin-bottom: 20px;
            span {
              display: block;
              line-height: 18px;
            }
          }
          .change-appt-btn {
            display: block;
            margin-top: 20px;
            margin-#{$rdirection}: 0;
            border-bottom: 1px solid $color-black-dark;
            text-align: center;
            text-decoration: none;
            line-height: 2;
          }
          .change-appt-btn.mobile {
            display: inline-block;
          }
          a.cancel-appt-btn {
            display: block;
            margin-top: 20px;
            margin-#{$rdirection}: 0;
            border-bottom: 1px solid $color-black-dark;
            text-align: center;
          }
          a.location-direction-links {
            display: block;
            margin-top: 20px;
            border-bottom: 1px solid $color-black-dark;
            text-decoration: none;
            text-align: center;
          }
        }
        .appointments-buttons.top {
          display: none;
        }
        .appointments-buttons.bottom {
          display: block;
          text-transform: uppercase;
        }
      }
    }
    .appt-book-overlay {
      .overlay-content-container {
        width: 225px;
        height: 232px;
        margin-#{$ldirection}: -150px;
        .overlay_container {
          height: auto;
          padding: 21px 0;
          #confirm-cancel {
            padding: 20px 10px;
          }
        }
        .overlay-content {
          h4 {
            letter-spacing: 0.11em;
            line-height: 1.1em;
            margin-bottom: 8px;
            margin-top: 13px;
          }
          p {
            margin: 0 auto;
            line-height: 1.3em;
            width: 82%;
          }
        }
      }
    }
    .appt-book-overlay.appt-book-services-overlay {
      .overlay-content-container {
        width: 300px;
        height: 232px;
        margin-#{$ldirection}: -150px;
      }
    }
    .appt-book-overlay.appt-book-book-conflict-overlay {
      .overlay-content-container {
        width: 300px;
        height: 232px;
        margin-#{$ldirection}: -150px;
      }
    }
    .appt-book-overlay.appt-book-datepicker-overlay {
      .overlay-content-container {
        .pika-label {
          letter-spacing: 1px;
        }
      }
    }
    .appt-book-overlay.my-appointments-overlay {
      .overlay-content-container {
        #confirm-cancel {
          p {
            margin-top: 12px;
            margin-bottom: 12px;
            br {
              display: none;
            }
          }
          .button.yes-btn {
            display: inline-block;
            float: none;
            width: 100px;
            margin-#{$rdirection}: auto;
          }
          .button.no-btn {
            display: inline-block;
            float: none;
            width: 100px;
          }
          .button {
            display: block;
            margin: 10px auto;
          }
          .viewcart-buttons {
            .go-shopping {
              display: block;
              margin: 10px auto;
            }
            .yes-btn.go-shopping {
              margin-#{$rdirection}: auto;
            }
          }
          .recommended-item__button {
            display: block;
            margin: 10px auto;
          }
          .yes-btn.recommended-item__button {
            margin-#{$rdirection}: auto;
          }
        }
        width: 300px;
        height: auto;
        min-height: 0;
        margin-#{$ldirection}: -150px;
        p {
          letter-spacing: 1px;
        }
        .canceled {
          h4 {
            width: auto;
          }
          p {
            width: 100%;
          }
          .book-new-btn {
            float: none;
            letter-spacing: 1px;
          }
        }
      }
    }
    .viewcart-buttons {
      .appt-book-overlay.my-appointments-overlay {
        .overlay-content-container {
          #confirm-cancel {
            .go-shopping {
              display: block;
              margin: 10px auto;
            }
            .yes-btn.go-shopping {
              margin-#{$rdirection}: auto;
            }
          }
        }
      }
    }
    .appt-book-content-header {
      .section-header {
        width: 100%;
      }
    }
  }
  @media (max-width: $medium-up) {
    .appt-book-overlay.appt-book-datepicker-overlay {
      .overlay-content-container {
        padding-top: 65px;
        padding-bottom: 38px;
      }
    }
  }
  @media (min-width: $medium-up) {
    .appt-book-overlay.appt-book-datepicker-overlay {
      .overlay-content-container {
        .pika-single {
          .pika-lendar {
            border-#{$rdirection}: 1px solid $border-color_dirty_white;
            width: 50%;
            padding-#{$rdirection}: 3%;
            padding-#{$ldirection}: 0.5%;
            margin: 0;
            &:last-child {
              border: none;
              padding-#{$rdirection}: 0.5%;
              padding-#{$ldirection}: 3%;
            }
          }
        }
        .pika-table {
          margin-top: 27px;
          th {
            padding: 0.7em 0 1em;
          }
          td {
            padding: 0.47em 0;
          }
        }
        .pika-button {
          padding: 25px 20px;
          line-height: 5px;
        }
      }
    }
  }
}
